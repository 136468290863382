import React from 'react';
import { Table, TabPane } from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWater, faBreadSlice } from '@fortawesome/free-solid-svg-icons'

function RatingBadge(props) {
    var className = "badge-danger";

    if (props.rating > 3) {
        className = "badge-warning";
    }
    if (props.rating > 6) {
        className = "badge-success";
    }
    if (props.muted) {
        className = "badge-light";
    }

    return (<span className={'badge badge-pill ' + className}>{props.rating}</span>);
}

function getSwellWindDirectionClass(direction, muted) {
    var className = "";

    switch (direction) {
        case "Offshore":
        case "Crossoff":
            className = "text-success";
            break;
        case "Crossoff":
            className = "text-warning";
            break;
        case "Cross":
            className = "text-warning";
            break;
        case "Onshore":
            className = "text-danger";
            break;
    }

    if (muted) {
        className = "text-muted";
    }

    return "text-center " + className;
}

export class WeatherTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tabId: props.tabId,
            date: props.date,
            currentHour: props.currentHour,
            weatherData: props.weatherData,
            tideDate: props.tideData,
            activeHour: 1
        };
    }

    componentDidMount() {
        this.state.weatherData.forEach((weather, i) => {
            if (weather.currentHour) {
                this.setState({ activeHour: i });
            }
        });
    }

    componentWillUnmount() { }

    toggleActiveHour = (hour) => {
        this.props.onSelectHour(hour);
        this.setState({ activeHour: hour });
    }

    renderWeatherTab() {
        var currentHours = new moment(this.state.currentHour, 'YYYY-MM-DD HH:mm:ss').hours(),
            mute = moment().isSame(this.state.date, 'day');

        return (
            <TabPane tabId={this.state.tabId} className="">
                <Table className="table-borderless mt-1">
                    <thead>
                        <tr className="bg-light border-top">
                            <th>&nbsp;</th>
                            {this.state.weatherData.map((weather, i) =>
                                <th key={weather.date} className={classnames({
                                    'text-center': true,
                                    'text-muted': mute && moment(weather.date, 'YYYY-MM-DD HH:mm:ss').hours() < currentHours,
                                    'table-primary': this.state.activeHour === i
                                })}

                                    onClick={() => { this.toggleActiveHour(i); }}
                                >
                                    <h6 className="text-uppercase m-0">
                                        <Moment format="HH:mm">
                                            {weather.date}
                                        </Moment>
                                    </h6>
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Surf rating</td>
                            {this.state.weatherData.map(weather =>
                                <td key={weather.date} className="text-center">
                                    <RatingBadge rating={weather.rating} muted={mute && moment(weather.date, 'YYYY-MM-DD HH:mm:ss').hours() < currentHours} />
                                </td>
                            )}
                        </tr>
                        <tr className="bg-light border-top">
                            <th scope="col" colSpan="5"><h6 className="text-uppercase m-0">Wind <i className="wi wi-strong-wind"></i></h6></th>
                        </tr>
                        <tr>
                            <td>Max gust (kts)</td>
                            {this.state.weatherData.map(weather =>
                                <td key={weather.date} className={classnames({
                                    'text-center': true,
                                    'text-muted': mute && moment(weather.date, 'YYYY-MM-DD HH:mm:ss').hours() < currentHours
                                })}>
                                    <NumberFormat value={weather.windGust} displayType={'text'} decimalScale={0} />
                                </td>
                            )}
                        </tr>
                        <tr>
                            <td>Wind speed (kts)</td>
                            {this.state.weatherData.map(weather =>
                                <td key={weather.date} className={classnames({
                                    'text-center': true,
                                    'text-muted': mute && moment(weather.date, 'YYYY-MM-DD HH:mm:ss').hours() < currentHours
                                })}>
                                    <NumberFormat value={weather.windSpeed} displayType={'text'} decimalScale={0} />
                                </td>
                            )}
                        </tr>
                        <tr>
                            <td>Wind direction</td>
                            {this.state.weatherData.map(weather =>
                                <td key={weather.date} className={getSwellWindDirectionClass(weather.swellWindDirectionSystemName, mute && moment(weather.date, 'YYYY-MM-DD HH:mm:ss').hours() < currentHours)}>
                                    <NumberFormat value={weather.windDirection} displayType={'text'} decimalScale={0} renderText={value => <i className={'h4 m-0 wi wi-wind from-' + value + '-deg'}></i>} />
                                    <span className="d-block"><h6 className="m-0"><small className="text-uppercase">{weather.swellWindDirection}</small></h6></span>
                                </td>
                            )}
                        </tr>
                        <tr className="bg-light border-top">
                            <th scope="col" colSpan="5"><h6 className="text-uppercase m-0">Swell <FontAwesomeIcon icon={faWater} /></h6></th>
                        </tr>
                        <tr>
                            <td>Wave height (m)</td>
                            {this.state.weatherData.map(weather =>
                                <td key={weather.date} className={classnames({
                                    'text-center': true,
                                    'text-muted': mute && moment(weather.date, 'YYYY-MM-DD HH:mm:ss').hours() < currentHours
                                })}>
                                    <NumberFormat value={weather.waveHeight} displayType={'text'} decimalScale={1} />
                                </td>
                            )}
                        </tr>
                        <tr>
                            <td>Period (sec)</td>
                            {this.state.weatherData.map(weather =>
                                <td key={weather.date} className={classnames({
                                    'text-center': true,
                                    'text-muted': mute && moment(weather.date, 'YYYY-MM-DD HH:mm:ss').hours() < currentHours
                                })}>
                                    <NumberFormat value={weather.swellPeriod} displayType={'text'} decimalScale={0} />
                                </td>
                            )}
                        </tr>
                        <tr>
                            <td>Swell height (m)</td>
                            {this.state.weatherData.map(weather =>
                                <td key={weather.date} className={classnames({
                                    'text-center': true,
                                    'text-muted': mute && moment(weather.date, 'YYYY-MM-DD HH:mm:ss').hours() < currentHours
                                })}>
                                    <NumberFormat value={weather.swellHeight} displayType={'text'} decimalScale={1} />
                                </td>
                            )}
                        </tr>
                        <tr>
                            <td>Swell direction</td>
                            {this.state.weatherData.map(weather =>
                                <td key={weather.date} className={classnames({
                                    'text-center': true,
                                    'text-muted': mute && moment(weather.date, 'YYYY-MM-DD HH:mm:ss').hours() < currentHours
                                })}>
                                    <NumberFormat value={weather.swellDirection} displayType={'text'} decimalScale={0} renderText={value => <i className={'h4 wi wi-wind from-' + value + '-deg'}></i>} />
                                </td>
                            )}
                        </tr>
                    </tbody>
                </Table>
            </TabPane>
        );
    }

    render() {
        return (this.renderWeatherTab());
    }
}