import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import NumberFormat from 'react-number-format';

export class TideClock extends React.Component {
  constructor(props) {
    super(props);

    this.tick = this.tick.bind(this);
    this.state = {
      data: this.props.data,
      nextTide: null,
      nextTideTime: null,
      nextTideType: null,
      nextTideTypeSystemName: null,
      nextTideTypeDescription: null
    };
  }

  componentDidMount() {
    this.tick();

    this.timerID = setInterval(
      () => this.tick(),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    const now = moment();

    for (const [i, tide] of this.state.data.entries()) {
      const tideDate = moment(tide.date, "YYYY-MM-DD HH:mm:ss");

      if (now.isSameOrBefore(tideDate)) {
        this.setState({
          nextTideType: tide.tideType,
          nextTideTypeSystemName: tide.tideTypeSystemName,
          nextTideTypeDescription: tide.tideTypeDescription,
          nextTide: moment.utc(tideDate.diff(now)).format("H:mm:ss"),
          nextTideTime: tideDate.format("HH:mm")
        });

        break;
      }
    }
  }

  renderClock() {
    return (
      <div className="table-responsive table-sm bg-light">
        <table className="table mb-0">
          <tbody>
            <tr>
              <td>
                <span className="d-block text-muted"><small>TIDE</small></span>
              </td>
              <td>
                <span className="d-block text-left font-weight-bold">{this.state.nextTideTypeDescription} <FontAwesomeIcon icon={this.state.nextTideTypeSystemName === 'Low' ? faArrowDown : faArrowUp} /></span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="d-block text-muted"><small>NEXT TURN</small></span>
              </td>
              <td>
                <span className="d-block text-left font-weight-bold">{this.state.nextTideTime} ({this.state.nextTideType})</span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="d-block text-muted"><small>TIME TO TURN</small></span>
              </td>
              <td>
                <span className="d-block text-left font-weight-bold">{this.state.nextTide}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="d-block text-muted"><small>WATER TEMP</small></span>
              </td>
              <td>
                <span className="d-block text-left font-weight-bold"><NumberFormat value={this.props.waterTemperature} displayType={'text'} decimalScale={0} />°C</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    let contents = this.props.data ? this.renderClock() : <div></div>;

    return (contents);
  }
}