import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Location } from './components/Location';
import { Forecast } from './components/Forecast';

import './css/custom.css'
import './css/weather-icons.css'
import './css/weather-icons-wind.css'

export default class App extends Component {
	static displayName = App.name;

	render() {
		return (
			<Layout>
				<Route exact path='/' component={Location} />
				<Route path='/surf-forecast/:systemName' component={Forecast} />
			</Layout>
		);
	}
}
