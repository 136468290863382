import React, { Component } from 'react'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import { Spinner } from 'reactstrap'
import MarkerClusterGroup from 'react-leaflet-markercluster';

import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

export class ForecastMap extends Component {
    static displayName = ForecastMap.name;

    constructor(props) {
        super(props);

        this.state = { 
            locationSystemName: this.props.locationSystemName,
            loading: true,
            weatherPoints: [], 
            lat: -41.838875, 
            lng: 171.7799, 
            zoom: 13 
        };
    }

    componentDidMount() {
		this.getData(this.state.locationSystemName);
	}

	async getData(locationSystemName) {
		const response = await fetch('WeatherForecast/Markers/' + locationSystemName);
		const data = await response.json();
		this.setState({ weatherPoints: data, loading: false });
	}

    renderMap(){
        const markers = [];
         var defaultPosition = [this.state.lat, this.state.lng];

        for(const [i, weatherPoint] of this.state.weatherPoints.entries()){
            var markerPosition = [weatherPoint.latitude, weatherPoint.longitude];

            if(weatherPoint.current){
                defaultPosition = markerPosition;
            }

            markers.push(
                <Marker key={weatherPoint.systemName} position={markerPosition}>
                    <Popup>
                        {weatherPoint.name}
                    </Popup>
                </Marker>
            );
        }

        return (
            <Map id="forecastMap" center={defaultPosition} zoom={this.state.zoom} maxZoom={18}>
                <TileLayer 
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <MarkerClusterGroup>
                    {markers}
                </MarkerClusterGroup>
            </Map>
        )
    }

    render() {
        let contents = this.state.loading ? <div className="text-center"><Spinner color="primary" /></div> : this.renderMap();

		return (contents);
    }
}