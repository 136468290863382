import React from 'react';
import { Card, CardTitle, CardBody, Row, Col } from 'reactstrap';
import { Chart } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import classnames from 'classnames';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import Moment from 'react-moment';

export function RatingCard(props) {
  const weather = props.data,
    currentDate = new moment();

  let rating = parseInt(weather.rating),
    ratingDiff = 10 - rating,
    bgColour = '#28a745',
    displayDate = "Today";

  if (rating < 7) {
    bgColour = '#ffc107';
  }

  if (rating < 4) {
    bgColour = '#dc3545';
  }

  const chartData = {
    labels: [rating, ratingDiff],
    datasets: [
      {
        backgroundColor: [
          Chart.helpers.color(bgColour).alpha(1).rgbString(),
          Chart.helpers.color('#f8f9fa').alpha(1).rgbString()
        ],
        borderColor: [bgColour, '#f8f9fa'],
        borderWidth: 1,
        borderColor: 'transparent',
        data: [rating, ratingDiff]
      }
    ]
  }

  if (!currentDate.isSame(weather.date, 'day')) {
    displayDate = <Moment format="dddd">{weather.date}</Moment>;
  }

  return (
    <Card className={classnames({ 'shadow': true, 'mb-2': true })}>
      <CardTitle className="p-2 mb-1">
        <h5 className="mb-0">{displayDate}</h5>
        <span className="d-block text-muted"><small><Moment format="DD MMM, HH:mm">{weather.date}</Moment></small></span>
      </CardTitle>
      <Row className="no-gutters">
        <Col className="col-md-6">
          <Row className="no-gutters text-center">
            <Col>
              <span className="d-block text-muted">{weather.weatherForecastName}</span>
            </Col>
          </Row>
          <Row className="no-gutters">
            <Col className="col-md-6">
              <div className="text-right">
                <i className={"h1 mt-2 mr-2 wi " + (!weather.weatherForecastIconClass ? "wi-day-sunny text-warning" : weather.weatherForecastIconClass)}></i>
              </div>
            </Col>
            <Col className="col-md-auto">
              <div className="display-4"><NumberFormat value={weather.airTemperature} displayType={'text'} decimalScale={0} /><small className="align-text-top">°</small></div>
            </Col>
          </Row>
        </Col>
        <Col className="col-md-6 pb-3 mx-0">
          <div className={classnames({ 'doughnut-rating-overlay': true, 'ml-2': rating < 10 })}>
            <span className="h2">{rating}</span><span className="text-muted">/10</span>
          </div>
          <Doughnut
            height={100}
            width={100}
            data={chartData}
            options={{
              responsive: false,
              maintainAspectRatio: false,
              cutoutPercentage: 70,
              legend: {
                display: false
              },
              tooltips: {
                enabled: false
              }
            }}
          />
        </Col>
      </Row>
      <CardBody className="p-0">
        <div className="table-responsive">
          <table className="table mb-0 bg-light">
            <tbody>
              <tr>
                <td>
                  <div>
                    <span className="d-block text-muted"><small>SWELL HEIGHT</small></span>
                    <span className="d-block font-weight-bold"><NumberFormat value={weather.swellHeight} displayType={'text'} decimalScale={1} />m</span>
                  </div>
                </td>
                <td>
                  <div >
                    <span className="d-block text-muted"><small>WAVE HEIGHT</small></span>
                    <span className="d-block font-weight-bold"><NumberFormat value={weather.waveHeight} displayType={'text'} decimalScale={1} />m</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <span className="d-block text-muted"><small>WIND</small></span>
                    <span className="d-block font-weight-bold"><NumberFormat value={weather.windSpeed} displayType={'text'} decimalScale={0} />kts</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span className="d-block text-muted"><small>GUSTS</small></span>
                    <span className="d-block font-weight-bold"><NumberFormat value={weather.windGust} displayType={'text'} decimalScale={0} />kts</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
}