import React from 'react';
import { Chart } from 'chart.js';
import { Line } from 'react-chartjs-2';

export function TideChart(props) {
  if (!props.data) {
    return (<div>Tide data not available at this time.</div>);
  }

  const data = JSON.parse(props.data.value),
    min = Math.min.apply(null, data),
    max = Math.max.apply(null, data);

  const chartData = (canvas) => {
    const ctx = canvas.getContext("2d"),
      gradient = ctx.createLinearGradient(0, 0, 0, 150);

    gradient.addColorStop(0, Chart.helpers.color('#2c8ef8').alpha(0.5).rgbString());
    gradient.addColorStop(0.5, Chart.helpers.color('#2c8ef8').alpha(0.2).rgbString());
    gradient.addColorStop(1, Chart.helpers.color('#2c8ef8').alpha(0).rgbString());

    return {
      labels: JSON.parse(props.data.date),
      datasets: [
        {
          fill: 'start',
          lineTension: 0.5,
          backgroundColor: gradient,
          borderColor: '#2c8ef8',
          borderWidth: 1,
          data: JSON.parse(props.data.value)
        }
      ]
    }
  }

  return (
    <div>
      <Line
        data={chartData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: false
          },
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              gridLines: {
                color: "rgba(0,0,0,0.05)"
              }
            }
            ],
            yAxes: [{
              ticks: {
                beginAtZero: false,
                autoSkip: false,
                min: 0.1,
                max: max,
                maxTicksLimit: 2,
                callback: function (value, index, values) {
                  return value.toString() + 'm';
                },
              },
              gridLines: {
                color: "rgba(0,0,0,0.05)",
                fontColor: "#fff"
              }
            }
            ]
          }
        }}
      />
    </div>
  );
}