import React, { Component } from 'react';
import { Spinner, Card, CardTitle, CardBody, CardImg } from 'reactstrap';
import { TabContent, Nav, NavItem, NavLink, Row, Col, Badge } from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';
import Moment from 'react-moment';
import { ForecastMap } from './ForecastMap';
import { TideChart } from './TideChart';
import { TideClock } from './TideClock';
import { RatingCard } from './RatingCard';
import { WeatherTab } from './WeatherTab';


function WeatherNav(props) {
	var currentDate = new moment(),
		displayDate = "TODAY",
		weather = props.weather,
		weatherClassName,
		rating = 1;

	if (!currentDate.isSame(props.date, 'day')) {
		displayDate = <Moment format="ddd">{props.date}</Moment>;
	}

	for (var i = 0; i < weather.length; i++) {
		var weatherDate = new moment(weather[i].date, 'YYYY-MM-DD HH:mm:ss'),
			weatherHours = weatherDate.hours();

		if (currentDate.isSame(props.date, 'day')) {
			if (weather[i].currentHour) {
				weatherClassName = weather[i].weatherForecastIconClass;
				rating = weather[i].rating;
			}
		} else {
			if (weatherHours == 10) {
				weatherClassName = weather[i].weatherForecastIconClass;
			}
			rating = weather[i].rating > rating ? weather[i].rating : rating;
		}
	}

	return (
		<NavItem>
			<NavLink className={props.className} onClick={props.onClick}>
				<div className="text-center">
					<h6 className="text-uppercase m-0">
						{displayDate}
					</h6>
					<span className="h6 d-block font-weight-light">
						<small>
							<Moment format="DD MMM">
								{props.date}
							</Moment>
						</small>
					</span>
					<div className="d-block">
						<i className={"h4 wi " + (!weatherClassName ? "wi-day-sunny text-warning" : weatherClassName)}></i>
						<Badge color="light" pill>{rating}</Badge>
					</div>
				</div>
			</NavLink>
		</NavItem >
	);
}

export class Forecast extends Component {
	static displayName = Forecast.name;

	constructor(props) {
		super(props);

		const { systemName } = this.props.match.params;
		this.toggleTab = this.toggleTab.bind(this);
		this.state = { systemName: systemName, forecasts: null, loading: true, activeTab: '0', activeHours: [] };
	}

	componentDidMount() {
		const { systemName } = this.props.match.params;

		this.populateWeatherData(systemName);
	}

	async populateWeatherData(systemName) {
		const response = await fetch('WeatherForecast/Forecast/' + systemName);
		const data = await response.json();
		this.setState({ forecasts: data, loading: false });
	}

	handleActiveHour = (activeHour) => {
		let activeHours = this.state.activeHours;
		activeHours[this.state.activeTab] = activeHour;
		this.setState({ activeHours: activeHours });
	}

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({ activeTab: tab });
		}
	}

	renderDays() {
		const createMarkup = htmlString => ({ __html: htmlString }),
			currentForecast = this.state.forecasts[this.state.activeTab],
			weatherData = JSON.parse(currentForecast.weatherJSON);

		let currentForecastHour = weatherData[this.state.activeHours[this.state.activeTab]];

		if (!currentForecastHour) {
			for (const [i, weather] of JSON.parse(currentForecast.weatherJSON).entries()) {
				if (this.state.activeTab == '0') {
					if (weather.currentHour) {
						currentForecastHour = weather;
					}
				} else {
					if (moment(weather.date, 'YYYY-MM-DD HH:mm:ss').hours() === 10) {
						currentForecastHour = weather;
					}
				}
			}
		}

		return (
			<div>
				<Row>
					<Col lg="8">
						<Card className="shadow mb-2">
							<CardTitle className="border-bottom m-2"><h3>{this.state.forecasts[0].name}</h3></CardTitle>
							<CardBody className="p-0">
								<Nav pills className="nav-fill m-1">
									{this.state.forecasts.map((forecast, i) =>
										<WeatherNav
											key={forecast.date}
											date={forecast.date}
											weather={JSON.parse(forecast.weatherJSON)}
											className={classnames({
												'active': this.state.activeTab === i.toString(),
												'mr-1': false,
												'mb-1': false
											})}
											onClick={() => { this.toggleTab(i.toString()); }}
										/>
									)}
								</Nav>
								<TabContent activeTab={this.state.activeTab}>
									{this.state.forecasts.map((forecast, i) =>
										<WeatherTab
											key={i}
											tabId={i.toString()}
											date={forecast.date}
											currentHour={forecast.currentHour}
											weatherData={JSON.parse(forecast.weatherJSON)}
											tideData={JSON.parse(forecast.tideJSON)}
											onSelectHour={this.handleActiveHour}
										/>
									)}
								</TabContent>
							</CardBody>
						</Card>
						<div className={classnames({ 'd-none': !this.state.forecasts[0].imageUrl })}>
							<Card className="shadow mb-2">
								<a href={this.state.forecasts[0].imageUrl} target="_blank">
									<CardImg top width="100%" src={this.state.forecasts[0].imageUrl} alt={this.state.forecasts[0].name} />
								</a>
								<div className="img-attribution text-right" dangerouslySetInnerHTML={createMarkup(this.state.forecasts[0].imageUrlCitation)}></div>
							</Card>
						</div>
					</Col>
					<Col lg="4">
						<RatingCard data={currentForecastHour} />
						<div>
							<Card className="shadow mb-2">
								<CardBody className="p-0">
									<CardTitle className="m-2"><h5>Tide</h5></CardTitle>
									<div className="text-center text-muted">
										<small>
											<Moment format="dddd, DD MMM">
												{currentForecast.date}
											</Moment>
										</small>
									</div>
									<TideChart data={JSON.parse(currentForecast.tideJSON)} />
									<TideClock data={JSON.parse(currentForecast.nextTideJSON)} waterTemperature={currentForecastHour.waterTemperature} />
								</CardBody>
							</Card>
						</div>
						<div>
							<Card className="shadow">
								<CardBody className="p-0">
									<CardTitle className="m-2"><h5>Map</h5></CardTitle>
									<ForecastMap locationSystemName={this.state.systemName} />
								</CardBody>
							</Card>
						</div>
					</Col>
				</Row>
			</div>
		);
	}

	render() {
		let contents = this.state.loading ? <div className="text-center"><Spinner color="primary" /></div> : this.renderDays();

		return (contents);
	}
}
