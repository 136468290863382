import React, { Component } from 'react';
import { Spinner, Card, CardImg, CardTitle, CardBody } from 'reactstrap';
import { Button, Row, Col, Badge, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import classnames from 'classnames';
import { BrowserRouter as Router, Link } from "react-router-dom";
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { Chart } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';


function RatingBadge(props) {
	var className = "badge-danger";

	if (props.rating > 3) {
		className = "badge-warning";
	}
	if (props.rating > 6) {
		className = "badge-success";
	}

	return (<span className={'badge badge-pill ' + className}>{props.rating}</span>);
}

function RatingButton(props) {
	var className = "danger";

	if (props.rating > 3) {
		className = "warning";
	}
	if (props.rating > 6) {
		className = "success";
	}

	return (<Button className="float-right rounded-0" color="light">Forecast <FontAwesomeIcon icon={faCaretRight} /></Button>)
}

export class Location extends Component {
	static displayName = Location.name;

	constructor(props) {
		super(props);
		this.state = {
			searchTimer: null,
			name: "",
			locations: [],
			loading: true
		};

		this.searchOnChange = this.searchOnChange.bind(this);
		this.populateWeatherData = this.populateWeatherData.bind(this);
	}

	componentDidMount() {
		this.populateWeatherData();
	}

	async populateWeatherData(name) {
		this.setState({ loading: true });

		const response = await fetch('location?name=' + this.state.name);
		const data = await response.json();

		this.setState({ locations: data, loading: false, searchTimer: null });
	}

	searchOnChange(e) {
		if (this.state.searchTimer) {
			window.clearTimeout(this.state.searchTimer);
		}

		this.setState({
			name: e.target.value,
			searchTimer: window.setTimeout(this.populateWeatherData, 1000)
		});
	}

	renderLocations() {
		var items = [],
			item = [],
			rowCount = 0,
			itemCount = 0;

		for (const [i, location] of this.state.locations.entries()) {
			var weather = JSON.parse(location.weatherJSON);

			if (!weather) {
				continue;
			}

			let rating = parseInt(weather.rating),
				ratingDiff = 10 - rating,
				bgColour = '#28a745';

			if (rating < 7) {
				bgColour = '#ffc107';
			}
			if (rating < 4) {
				bgColour = '#dc3545';
			}

			const chartData = {
				labels: [rating, ratingDiff],
				datasets: [
					{
						backgroundColor: [
							Chart.helpers.color(bgColour).alpha(1).rgbString(),
							Chart.helpers.color('#f8f9fa').alpha(1).rgbString()
						],
						borderColor: [bgColour, '#f8f9fa'],
						borderWidth: 1,
						borderColor: 'transparent',
						data: [rating, ratingDiff]
					}
				]
			}

			item.push(
				<Col key={i} md="4" className="mb-2">
					<Card className={classnames({ 'shadow': true })}>
						<CardTitle className="p-2 mb-1">
							<h3 className="mb-0">{location.name}</h3>
							<span className="d-block text-muted"><small>{location.placeName}</small></span>
							<Link to={"/surf-forecast/" + location.systemName} className="view-forecast-button">
								<RatingButton rating={weather.rating} />
							</Link>
						</CardTitle>
						<Row className="no-gutters">
							<Col className="col-md-6">
								<Row className="no-gutters text-center">
									<Col>
										<span className="d-block text-muted">{weather.weatherForecastName}</span>
									</Col>
								</Row>
								<Row className="no-gutters">
									<Col className="col-md-6">
										<div className="text-right">
											<i className={"h1 mt-2 mr-2 wi " + (!weather.weatherForecastIconClass ? "wi-day-sunny text-warning" : weather.weatherForecastIconClass)}></i>
										</div>
									</Col>
									<Col className="col-md-auto">
										<div className="display-4"><NumberFormat value={weather.airTemperature} displayType={'text'} decimalScale={0} /><small className="align-text-top">°</small></div>
									</Col>
								</Row>
							</Col>
							<Col className="col-md-6 pb-3 mx-0">
								<div className={classnames({ 'doughnut-rating-overlay': true, 'ml-2': rating < 10 })}>
									<span className="h2">{rating}</span><span className="text-muted">/10</span>
								</div>
								<Doughnut
									height={100}
									width={100}
									data={chartData}
									options={{
										responsive: false,
										maintainAspectRatio: false,
										cutoutPercentage: 70,
										legend: {
											display: false
										},
										tooltips: {
											enabled: false
										}
									}}
								/>
							</Col>
						</Row>

						<CardBody className="p-0">
							<div className="table-responsive">
								<table className="table mb-0 bg-light">
									<tbody>
										<tr>
											<td>
												<div>
													<span className="d-block text-muted"><small>SWELL HEIGHT</small></span>
													<span className="d-block font-weight-bold"><NumberFormat value={weather.swellHeight} displayType={'text'} decimalScale={1} />m</span>
												</div>
											</td>
											<td>
												<div >
													<span className="d-block text-muted"><small>WAVE HEIGHT</small></span>
													<span className="d-block font-weight-bold"><NumberFormat value={weather.waveHeight} displayType={'text'} decimalScale={1} />m</span>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<div>
													<span className="d-block text-muted"><small>WIND</small></span>
													<span className="d-block font-weight-bold"><NumberFormat value={weather.windSpeed} displayType={'text'} decimalScale={0} />kts</span>
												</div>
											</td>
											<td>
												<div>
													<span className="d-block text-muted"><small>GUSTS</small></span>
													<span className="d-block font-weight-bold"><NumberFormat value={weather.windGust} displayType={'text'} decimalScale={0} />kts</span>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</CardBody>
					</Card>
				</Col>
			)

			itemCount++;
			rowCount = itemCount / 3;

			if (Number.isInteger(rowCount) && (rowCount) > 0) {
				items.push(<Row key={rowCount}>{item}</Row>)
				item = [];
			}
		}

		if (item.length > 0) {
			items.push(<Row key={rowCount}>{item}</Row>)
		}

		return (
			<div>
				{items.length > 0 ? items : <p className="font-italic">No results found</p>}
			</div>
		);
	}

	render() {
		return (
			<div>
				<InputGroup className="mb-2">
					<InputGroupAddon addonType="prepend">
						<InputGroupText><FontAwesomeIcon icon={faSearch} /></InputGroupText>
					</InputGroupAddon>
					<Input placeholder="Search for break..." value={this.state.value} onChange={this.searchOnChange} />
				</InputGroup>
				{this.state.loading ? <div className="text-center"><Spinner color="primary" /></div> : this.renderLocations()}
			</div>
		);
	}
}
