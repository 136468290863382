import React, { Component } from 'react';

export class Footer extends Component {
	constructor(props) {
		super(props);
	}
	
	render() {
		return (
			<footer className="footer mt-3">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12">
							IKA Web
						</div>
					</div>
				</div>
			</footer>
		);
	}
}
